import React, { useContext, useEffect, useState } from 'react';
import PaymentSummaryHeader from '../../components/PaymentSummaryHeader/PaymentSummaryHeader.jsx';
import PaymentSummaryBreakdown from '../../components/PaymentSummaryBreakdown/PaymentSummaryBreakdown.jsx';
import { useNavigate } from 'react-router-dom';
import DualButtons from '../../components/DualButtons/DualButtons.jsx';
import { UpdateRocketContext } from '../../services/context/RocketContext.js';
import { MoneyFormatterContext } from '../../services/context/MoneyFormatterContext.js';
import { PaymentAmountContext } from '../../services/context/PaymentAmountContext.js';
import ROUTES from '../../services/Constants/GlobalRoutes.jsx';
import { defaultAnalyticsVariables, events, pagePrefix, traceId } from '../../services/Constants/Analytics.js';
import useApis from '../../services/hooks/useApis.js';
import { BearerTokenContext, UpdateBearerTokenContext } from '../../services/context/BearerTokenContext.js';
import useAuthService from '../../services/hooks/useAuthService.js';
import { UpdateMiniAppPaymentContext } from '../../services/context/MiniAppContext.js';
import { CurrentCouponContext } from '../../services/context/CurrentCouponContext.js';
import MiniAppMessageTypes from '../../services/Constants/MiniAppMessageTypes.js';
import Loading from '../../components/Loading/Loading.jsx';
import CartIcon from '../../assets/CartIcon.jsx';
import { UpdateSnackBarContext } from '../../services/context/SnackBarContext.js';
import { handlePaymentError } from '../../services/ErrorHandler.jsx';
import { startTransaction, withProfiler, captureMessage } from '@sentry/react';
import HttpCodes from '../../services/Constants/HttpCodes.js';
import { withSentrySpan } from '../../services/Sentry.js';
import { SentryConfig } from '../../services/Constants/Sentry.js';
import { UpdateHistoryContext } from '../../services/context/HistoryContext.js';
import { UpdateEligibilityContext } from '../../services/context/EligibilityContext.js';
import { AnalyticsPageContext } from '../../services/context/AnalyticsPageContext.js';
import { NetworkError } from '../../services/Constants/Errors.js';

/**
 * @returns {JSX.Element} PaymentSummary Page
 */
function PaymentSummary() {
  const bearerTokenContext = useContext(BearerTokenContext);
  const updateBearerToken = useContext(UpdateBearerTokenContext);
  const { getBearerToken, createEarlyRepayment } = useApis();
  const { doAuth } = useAuthService();
  const { reservationId, advanceHistory: { advanceDetail: { orderId } } } = useContext(CurrentCouponContext);
  const analyticsName = useContext(AnalyticsPageContext);
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const navigateTo = useNavigate();
  const setPaymentHandler = useContext(UpdateMiniAppPaymentContext);
  const setSnackBar = useContext(UpdateSnackBarContext);
  const setRocket = useContext(UpdateRocketContext);
  const paymentAmount = Number(useContext(PaymentAmountContext));
  const payment = MoneyFormatter.get(paymentAmount);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [loading, setLoading] = useState(/** @type {string} */null);
  const setEligibility = useContext(UpdateEligibilityContext);
  const setHistory = useContext(UpdateHistoryContext);

  const tryEarlyPayment = async bearerToken => {
    const authCode = await doAuth();
    const response = await createEarlyRepayment(bearerToken, authCode, {
      orderId,
      paymentAmount,
      reservationId,
    });
    const paymentUrl = response.redirectActionForm.redirectUrl;
    setLoading('Getting ready for payment');

    if (!window.my) {
      // eslint-disable-next-line no-alert
      const mocked = confirm('mock success?');
      setPaymentResponse({ ok: mocked });
    } else {
      setPaymentHandler(() => message => setPaymentResponse(message.data));
      window.my.postMessage({
        messageType: MiniAppMessageTypes.payment,
        data: { paymentUrl },
      }, '*');
    }
  };

  const primaryOnClick = async bearerToken => {
    setLoading('Creating Early Repayment...');
    const earlyRepaymentTransaction = startTransaction(SentryConfig.payments.earlyRepayment.transaction);

    try {
      await withSentrySpan(
        earlyRepaymentTransaction,
        SentryConfig.payments.earlyRepayment.spans.tryEarlyRepayment,
        async () => tryEarlyPayment(bearerToken),
      );
    } catch (error) {
      window.utag?.link({
        ...defaultAnalyticsVariables,
        page_name: analyticsName,
        event_name: [events.error],
        link_id: `${pagePrefix}: error`,
        event_error_name: 'early repayment error',
        event_error_code: error.response?.headers?.get(traceId),
        event_error_type: 'system error',
      });

      setPaymentResponse({
        ok: false,
        error,
      });
    } finally { earlyRepaymentTransaction.finish(); }
  };

  /**
   * @param {NetworkError} error - failed network response
   */
  const handleNetworkError = error => {
    const { response } = error;
    switch (response.status) {
      case HttpCodes.UNAUTHORIZED:
      // falls through

      case HttpCodes.FORBIDDEN: {
        setLoading('Retrying');
        doAuth()
          .then(authCode => getBearerToken(authCode))
          .then(bearerResponse => {
            updateBearerToken(bearerResponse.data.tokens.accessToken);
            return primaryOnClick(bearerResponse.data.tokens.accessToken);
          });
        break;
      }

      default: {
        captureMessage(`Unexpected network response in early repayment: ${response?.status}`, 'warning');
        setSnackBar({
          icon: <CartIcon />,
          body: 'Whoops, something went wrong',
        });
        break;
      }
    }
  };

  useEffect(() => {
    if (paymentResponse) {
      setLoading(null);
      if (paymentResponse.ok) {
        setHistory(null);
        setEligibility(null);
        setRocket({
          analytics: {
            ...defaultAnalyticsVariables,
            page_name: `${pagePrefix}: payment success`,
            event_name: [
              events.pageView,
              events.journeyEnd,
            ],
          },
          body: <p className='v-container-h'>{MoneyFormatter.format(payment)} has been deducted from your account.</p>,
          header: <h3>Payment successful!</h3>,
          onContinue: () => navigateTo(ROUTES.MY_VOUCHERS, { replace: true }),
        });
        navigateTo(ROUTES.SUCCESS);
      } else {
        const { error, resultCode } = paymentResponse;
        if (error instanceof NetworkError) {
          handleNetworkError(error);
        } else {
          handlePaymentError(resultCode, setSnackBar);
        }
      }
    }
  }, [paymentResponse]);

  return (
    <>
      <PaymentSummaryHeader />
      <PaymentSummaryBreakdown />
      <DualButtons
        primaryOnClick={ () => primaryOnClick(bearerTokenContext) }
        primaryText={ <>Pay {MoneyFormatter.format(payment)}</> }
        secondaryOnClick={ () => navigateTo(ROUTES.MY_VOUCHERS) }
        secondaryText={ <>Cancel</> }
      />
      {loading && <Loading headerText={ loading } />}
    </>
  );
}

export default withProfiler(PaymentSummary);
