import React, { useContext } from 'react';
import './ManagePaymentDetails.css';
import KeyValueList from '../KeyValueList/KeyValueList.jsx';
import { MoneyFormatterContext } from '../../services/context/MoneyFormatterContext.js';
import { CurrentCouponContext, CurrentCouponLoanDetailsContext } from '../../services/context/CurrentCouponContext.js';
import TopBack from '../TopBack/TopBack.jsx';

/**
 * @returns {JSX.Element} ManagePaymentDetails Component
 */
export default function ManagePaymentDetails() {
  /**
   * @param {Date} date - date to format
   * @returns {string} formatted date
   */
  const dateStringFormatter = date => `${date.toLocaleString('default', { day: 'numeric' })} ${date.toLocaleString('default', {
    year: 'numeric',
    month: 'long',
  })}`;

  const currentAdvance = useContext(CurrentCouponContext) ?? {};
  const loanDetails = useContext(CurrentCouponLoanDetailsContext);
  const expiryDate = new Date(currentAdvance.expiryDate);
  const expiryText = dateStringFormatter(expiryDate);
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const purchaseDate = new Date(currentAdvance.purchaseDate);
  const purchaseDateText = dateStringFormatter(purchaseDate);
  const { orderId, loanMaturity } = currentAdvance.advanceHistory.advanceDetail;
  const principalOutstanding = MoneyFormatter.get(loanDetails.principal);
  const totalOutstanding = MoneyFormatter.get(loanDetails.outstanding);
  const advanceFees = MoneyFormatter.get(loanDetails.fees);
  const paymentDueDate = new Date(purchaseDate);
  paymentDueDate.setDate(paymentDueDate.getDate() + loanMaturity);
  const paymentDueText = dateStringFormatter(paymentDueDate);
  return (
    <section className='manage-payment-details v-container'>
      <TopBack />
      <h3>Manage Payments</h3>
      <section className='voucher-details'>
        <KeyValueList object={
          {
            'Voucher type': currentAdvance.name,
            'Expiry date': expiryText,
          }
        }
        />
      </section>
      <section className='advance-details'>
        <h4>Advance details:</h4>
        <KeyValueList object={
          {
            'Payment method': 'Advance',
            'Order ID': orderId,
            'Outstanding amount': MoneyFormatter.format(principalOutstanding),
            ...(Number(advanceFees.amount) && { 'Additional fees': MoneyFormatter.format(advanceFees) }),
            'Date of purchase': purchaseDateText,
            'Payment due': paymentDueText,
          }
        }
        />
      </section>
      <section className='total'>
        <KeyValueList object={ { Total: MoneyFormatter.format(totalOutstanding) } } />
      </section>
    </section>
  );
}
