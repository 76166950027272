import React, { useContext } from 'react';
import './BuyAgain.css';
import RedoIcon from '../../assets/RedoIcon.jsx';
import { CartContext, UpdateCartContext } from '../../services/context/CartContext.js';
import BinIcon from '../../assets/BinIcon.jsx';
import PlusIcon from '../../assets/PlusIcon.jsx';
import { getLastIndexById } from '../../services/Find.js';
import PropTypeShapes from '../../services/Constants/PropTypeShapes.jsx';
import { defaultAnalyticsVariables, events, pagePrefix } from '../../services/Constants/Analytics.js';
import { AnalyticsPageContext } from '../../services/context/AnalyticsPageContext.js';
import { getVendorCategory, getVoucherVendor } from '../../services/ReverseHierarchySearch.js';
import { CategoriesContext } from '../../services/context/CategoriesContext.js';
import { Gift } from '../../services/Constants/ObjectDefinitions.js';
import MinusIcon from '../../assets/MinusIcon.jsx';
import { UpdateSnackBarContext } from '../../services/context/SnackBarContext.js';
import { MoneyFormatterContext } from '../../services/context/MoneyFormatterContext.js';
import FlagIcon from '../../assets/FlagIcon.jsx';
import { cartMax } from '../../services/Constants/config.js';

/**
 * @param {object} props - the props of the component
 * @param {Gift} props.gift - the gift to buy again
 * @returns {JSX.Element} BuyAgain Component
 */
export default function BuyAgain({ gift }) {
  const { voucher } = gift;
  const cart = useContext(CartContext);
  const MoneyFormatter = useContext(MoneyFormatterContext);
  const setCart = useContext(UpdateCartContext);
  const categories = useContext(CategoriesContext);
  const updateSnackBar = useContext(UpdateSnackBarContext);
  const num = cart.filter(item => item.id === voucher.id).length;

  const analyticsName = useContext(AnalyticsPageContext);

  const buyAgainAnalyticsData = {
    ...defaultAnalyticsVariables,
    page_name: analyticsName,
    product_category: getVendorCategory(categories, getVoucherVendor(categories, voucher))?.name,
    product_name: voucher.name,
    product_price: MoneyFormatter.getValue(voucher.cost).amount,
    product_id: voucher.id,
    product_quantity: 1,
  };
  const increase = () => {
    if (cart.length >= cartMax) {
      updateSnackBar({
        icon: <FlagIcon />,
        body: 'Cart limit reached',
      });
      return;
    }

    window.utag?.link({
      ...buyAgainAnalyticsData,
      event_name: [
        events.interaction,
        events.addToCart,
      ],
      link_id: `${pagePrefix}: add to cart`,
    });

    setCart([
      ...cart,
      voucher,
    ]);
  };

  const decrease = () => {
    window.utag?.link({
      ...buyAgainAnalyticsData,
      event_name: [
        events.interaction,
        events.removeFromCart,
      ],
      link_id: `${pagePrefix}: cart remove`,
    });

    const lastIndex = getLastIndexById(cart, voucher);
    setCart([
      ...cart.slice(0, lastIndex),
      ...cart.slice(lastIndex + 1),
    ]);
  };

  return num ? (<button className='buy-again v-container'>
    Buy again
    <main className='container-v'>
      <button
        onClick={ decrease }
        className='container'
      >
        {num > 1 ? <MinusIcon /> : <BinIcon />}
      </button>
      <p>{num}</p>
      <button
        onClick={ increase }
        className='container'
      >
        <PlusIcon />
      </button>
    </main>
  </button>
  ) : (
    <button
      className='container-v'
      onClick={ increase }
    >
      Buy again
      <RedoIcon />
    </button>
  );
}

BuyAgain.propTypes = { gift: PropTypeShapes.gift };
